import React, { useEffect, useState } from 'react';
import SignatureCanvas from '../components/forms/SignatureCanvas';
import LoanPrintView from '../components/LoanPrintView';

const EquipmentLoan = () => {
  const [formData, setFormData] = useState({
    Bezeichnung: '',
    Inventarnummer: '',
    UhrzeitVon: '',
    UhrzeitBis: '',
    Schäden: '',
    Name: '',
    Adresse: '',
    Telefonnumer: '',
    Datum: ''
  });
  
  const [signature, setSignature] = useState(null);
  // Dans votre composant de formulaire
    const [isSigningMode, setIsSigningMode] = useState(false);

    useEffect(() => {
    if (isSigningMode) {
        document.body.classList.add('signing');
    } else {
        document.body.classList.remove('signing');
    }
    return () => document.body.classList.remove('signing');
    }, [isSigningMode]);


  const [showPrintView, setShowPrintView] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!signature) {
      alert('Bitte unterschreiben Sie das Formular');
      return;
    }
    setShowPrintView(true);
  };

  if (showPrintView) {
    return (
      <LoanPrintView 
        formData={formData} 
        signature={signature}
        onBack={() => setShowPrintView(false)}
      />
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 pt-16">
      <h1 className="text-2xl font-bold mb-6">Ausleihe von Geräten Vitamin P Projekt</h1>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Verleihgegenstand Section */}
        <section className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Verleihgegenstand</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Bezeichnung/ Umfang
              </label>
              <input
                type="text"
                name="Bezeichnung"
                value={formData.Bezeichnung}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Inventarnummer
              </label>
              <input
                type="text"
                name="Inventarnummer"
                value={formData.Inventarnummer}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Uhrzeit von
                </label>
                <input
                  type="time"
                  name="UhrzeitVon"
                  value={formData.UhrzeitVon}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 rounded-lg p-2"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Uhrzeit bis
                </label>
                <input
                  type="time"
                  name="UhrzeitBis"
                  value={formData.UhrzeitBis}
                  onChange={handleInputChange}
                  className="w-full border border-gray-300 rounded-lg p-2"
                  
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Aufgetretene Schäden
              </label>
              <textarea
                name="Schäden"
                value={formData.Schäden}
                onChange={handleInputChange}
                rows="3"
                className="w-full border border-gray-300 rounded-lg p-2"
              />
            </div>
          </div>
        </section>

        {/* Ausgegeben an Section */}
        <section className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Ausgegeben an</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name, Vorname
              </label>
              <input
                type="text"
                name="Name"
                value={formData.Name}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Adresse
              </label>
              <input
                type="text"
                name="Adresse"
                value={formData.Adresse}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Telefonnummer
              </label>
              <input
                type="tel"
                name="Telefonnumer"
                value={formData.Telefonnumer}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Datum
              </label>
              <input
                type="date"
                name="Datum"
                value={formData.Datum}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-lg p-2"
                required
              />
            </div>
          </div>
        </section>

        {/* Signature Section */}
        <section className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-4">Unterschrift</h2>
          <SignatureCanvas 
                onSign={(signature) => {
                    setSignature(signature);
                    setIsSigningMode(!!signature);
                }} 
                />
        </section>

        {/* Terms Section */}
        <section className="bg-white rounded-lg shadow-sm p-6">
          <h2 className="text-lg font-semibold mb-2">Verleihbedingungen</h2>
          <div className="text-xs text-gray-600 space-y-1">
            <p>•Die Geräte werden zur Nutzung ausschließlich im rahmen des Projekts Vitamin P. überlassen.</p>
            <p>•Die Geräte sind pfleglich zu behandeln.</p>
            {/* Add other terms here */}
          </div>
        </section>

        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-colors"
        >
          Speichern und weiter
        </button>
      </form>
    </div>
  );
};

export default EquipmentLoan;