import React from 'react';

const NavButton = ({ onClick, children, icon: Icon }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center justify-center gap-2 w-64 p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-all transform hover:-translate-y-0.5"
    >
      {Icon && <Icon className="w-5 h-5" />}
      <span className="text-base font-medium text-gray-800">{children}</span>
    </button>
  );
};

export default NavButton;