import React from 'react';

const Settings = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold mb-4">Einstellungen</h2>
    <div className="bg-white rounded-lg shadow p-4">
      <p>Settings interface will be displayed here</p>
    </div>
  </div>
);

export default Settings;