import React from 'react';
import NavButton from '../components/NavButton';
import { useNavigate } from 'react-router-dom';
import { Plus, Clock, ClipboardList, Settings } from 'lucide-react';

const Home = () => {
  const navigate = useNavigate();
  
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
      {/* Logo */}
      <div className="mb-12">
        <img 
          src="/images/logoVKII.png"
          alt="VKII Logo" 
          className="rounded-full bg-gray-200 size-30"
        />
      </div>
      
      {/* Welcome Text */}
      <div className="text-center mb-12">
        <h1 className="text-3xl font-bold mb-3 text-gray-900">
          Willkommen bei VKII Equipment Management
        </h1>
        <p className="text-gray-600">
          Wählen Sie eine Option aus, um fortzufahren
        </p>
      </div>

      {/* Navigation Buttons */}
      <div className="flex flex-col items-center space-y-3">
        <NavButton
          onClick={() => navigate('ausleihen')}
          icon={() => <Plus className="w-5 h-5" />}
        >
          Neue Ausleihe
        </NavButton>

        <NavButton
          onClick={() => navigate('history')}
          icon={() => <Clock className="w-5 h-5" />}
        >
          Ausleihverlauf
        </NavButton>

        <NavButton
          onClick={() => navigate('inventory')}
          icon={() => <ClipboardList className="w-5 h-5" />}
        >
          Inventarverwaltung
        </NavButton>

        <NavButton
          onClick={() => navigate('settings')}
          icon={() => <Settings className="w-5 h-5" />}
        >
          Einstellungen
        </NavButton>
      </div>
    </div>
  );
};

export default Home;