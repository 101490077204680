// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import EquipmentLoan from './pages/EquipmentLoan';
import LoanHistory from './pages/LoanHistory';
import InventoryManagement from './pages/InventoryManagement';
import Settings from './pages/Settings';

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ausleihen" element={<EquipmentLoan />} />
          <Route path="/history" element={<LoanHistory />} />
          <Route path="/inventory" element={<InventoryManagement />} />
          <Route path="/settings" element={<Settings />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;