// src/components/forms/SignatureCanvas.js
import React, { useRef, useEffect, useState } from 'react';

const SignatureCanvas = ({ onSign }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [context, setContext] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      updateCanvasSize();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const updateCanvasSize = () => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    
    // Hauteurs différentes selon le device
    const height = isMobile ? 150 : 200;
    
    // Ajuster la largeur au conteneur
    const width = container.offsetWidth;
    
    // Définir les dimensions du canvas avec le bon ratio de pixels
    canvas.width = width * window.devicePixelRatio;
    canvas.height = height * window.devicePixelRatio;
    
    // Appliquer les dimensions CSS
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    
    // Configurer le contexte
    const ctx = canvas.getContext('2d');
    ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
    ctx.strokeStyle = '#000000';
    ctx.lineWidth = isMobile ? 3 : 2; // Ligne plus épaisse sur mobile
    ctx.lineCap = 'round';
    setContext(ctx);
  };

  useEffect(() => {
    updateCanvasSize();
  }, [isMobile]);

  const getCoordinates = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    
    // Calculer le ratio entre les dimensions réelles et affichées
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    if (e.touches && e.touches[0]) {
      const touch = e.touches[0];
      return {
        x: (touch.clientX - rect.left) * scaleX / window.devicePixelRatio,
        y: (touch.clientY - rect.top) * scaleY / window.devicePixelRatio
      };
    }

    return {
      x: (e.clientX - rect.left) * scaleX / window.devicePixelRatio,
      y: (e.clientY - rect.top) * scaleY / window.devicePixelRatio
    };
  };

  const startDrawing = (e) => {
    e.preventDefault();
    const coords = getCoordinates(e);
    context.beginPath();
    context.moveTo(coords.x, coords.y);
    setIsDrawing(true);
  };

  const draw = (e) => {
    e.preventDefault();
    if (!isDrawing) return;

    const coords = getCoordinates(e);
    context.lineTo(coords.x, coords.y);
    context.stroke();
  };

  const stopDrawing = () => {
    if (isDrawing) {
      context.closePath();
      setIsDrawing(false);
      onSign(canvasRef.current.toDataURL());
    }
  };

  const clear = () => {
    const canvas = canvasRef.current;
    context.clearRect(0, 0, canvas.width, canvas.height);
    onSign(null);
  };

  return (
    <div 
      ref={containerRef} 
      className="w-full"
    >
      <div className={`
        relative bg-white border border-gray-300 rounded-lg overflow-hidden
        ${isMobile ? 'shadow-sm' : 'shadow-md'}
      `}>
        {/* Message d'instruction */}
        {!isDrawing && !onSign && (
          <div className="absolute inset-0 flex items-center justify-center text-gray-400 pointer-events-none">
            <span className={`text-center ${isMobile ? 'text-sm' : 'text-base'}`}>
              Hier unterschreiben
            </span>
          </div>
        )}
        
        <canvas
          ref={canvasRef}
          className={`
            w-full touch-none cursor-crosshair
            ${isMobile ? 'bg-gray-50' : 'bg-white'}
          `}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
          onTouchStart={startDrawing}
          onTouchMove={draw}
          onTouchEnd={stopDrawing}
          style={{
            touchAction: 'none',
            WebkitTapHighlightColor: 'transparent'
          }}
        />
      </div>

      {/* Boutons de contrôle */}
      <div className={`
        mt-2 flex justify-end gap-2
        ${isMobile ? 'px-2' : 'px-4'}
      `}>
        <button
          type="button"
          onClick={clear}
          className={`
            px-4 py-2 text-gray-700 rounded-lg transition-colors
            ${isMobile 
              ? 'text-xs bg-gray-100 hover:bg-gray-200' 
              : 'text-sm bg-gray-100 hover:bg-gray-200'
            }
          `}
        >
          Unterschrift löschen
        </button>
      </div>
    </div>
  );
};

export default SignatureCanvas;