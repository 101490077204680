// src/components/LoanPrintView.js
import React from 'react';

const LoanPrintView = ({ formData, signature, onBack }) => {
  return (
    <div className="bg-white">
      {/* A4 Container - Further reduced by 5% */}
      <div className="w-[179.55mm] h-[254mm] mx-auto bg-white p-[10mm] print:p-[10mm] print:shadow-none shadow-lg">
        {/* Header */}
        <div className="flex items-start justify-between mb-4">
          <img 
            src="/images/logoVKII.png"
            alt="VKII Logo"
            className="h-16" // Reduced from h-20
          />
          <div className="text-[10px]"> {/* Reduced from 11px */}
            Association des Ingénieurs et Informaticiens Camerounais(AIIC)<br />
            Cameroon Association of Engineers amd Computer Scientists(CAECS)
          </div>
        </div>

        {/* Main Content Area */}
        <div className="border border-black p-4 mb-4"> {/* Reduced border and padding */}
          <h2 className="text-lg font-bold mb-5 text-center">Ausleihe von Geräten Vitamin P Projekt</h2>

          {/* Verleihgegenstand Section */}
          <div className="mb-5">
            <h5 className="text-base font-bold mb-2">Verleihgegenstand</h5>
            <table className="w-full border-collapse text-xs"> {/* Reduced text size */}
              <tbody>
                <tr className="border border-gray-400">
                  <td className="p-2 border border-gray-400 w-1/3 bg-gray-50">Bezeichnung/ Umfang</td>
                  <td className="p-2 border border-gray-400">{formData.Bezeichnung}</td>
                </tr>
                <tr className="border border-gray-400">
                  <td className="p-2 border border-gray-400 bg-gray-50">Inventarnummer</td>
                  <td className="p-2 border border-gray-400">{formData.Inventarnummer}</td>
                </tr>
                <tr className="border border-gray-400">
                  <td className="p-2 border border-gray-400 bg-gray-50">Uhrzeit von/bis</td>
                  <td className="p-2 border border-gray-400">
                    Von: {formData.UhrzeitVon} Bis: {formData.UhrzeitBis}
                  </td>
                </tr>
                <tr className="border border-gray-400">
                  <td className="p-2 border border-gray-400 bg-gray-50">Aufgetretene Schäden</td>
                  <td className="p-2 border border-gray-400 min-h-[2.5em]">{formData.Schäden || '------------------------'}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Ausgegeben an Section */}
          <div className="mb-5">
            <h5 className="text-base font-bold mb-2">Ausgegeben an:</h5>
            <table className="w-full border-collapse text-xs">
              <tbody>
                <tr className="border border-gray-400">
                  <td className="p-2 border border-gray-400 w-1/3 bg-gray-50">Name, Vorname</td>
                  <td className="p-2 border border-gray-400">{formData.Name}</td>
                </tr>
                <tr className="border border-gray-400">
                  <td className="p-2 border border-gray-400 bg-gray-50">Adresse</td>
                  <td className="p-2 border border-gray-400">{formData.Adresse}</td>
                </tr>
                <tr className="border border-gray-400">
                  <td className="p-2 border border-gray-400 bg-gray-50">Telefonnummer</td>
                  <td className="p-2 border border-gray-400">{formData.Telefonnumer}</td>
                </tr>
                <tr className="border border-gray-400">
                  <td className="p-2 border border-gray-400 bg-gray-50">Datum</td>
                  <td className="p-2 border border-gray-400">{formData.Datum}</td>
                </tr>
                <tr className="border border-gray-400">
                  <td className="p-2 border border-gray-400 bg-gray-50">Unterschrift der ausleihenden Person</td>
                  <td className="p-2 border border-gray-400">
                    <img src={signature} alt="Signature" className="h-16 max-w-full" /> {/* Reduced from h-20 */}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Terms Section */}
          <div className="mb-5">
            <h6 className="text-sm font-bold mb-2">Verleihbedingungen</h6>
            <div className="text-[9px] leading-relaxed space-y-0.5"> {/* Reduced from 10px */}
              <p>•Die Geräte werden zur Nutzung ausschließlich im rahmen des Projekts Vitamin P. überlassen, eine Nutzung zu anderen Zwecken (privat oder gewerblich) ist unzulässig.</p>
              <p>•Die Geräte sind pfleglich zu behandeln, bei fahrlässig verursachten Schäden sowie bei Diebstahl behält der VKII Ruhrbezirk sich vor, die Ausleihenden in Haftung zu nehmen. Alle aufgetretenen Schäden sind bei Rückgabe des Gerätes mitzuteilen.</p>
              <p>•Der VKII Ruhrbezirk übernimmt keine Gewähr für die Funktionstüchtigkeit der verliehenen Geräte; insbesondere übernimmt der VKII Ruhrbezirk keine Haftung für durch Datenverluste o.ä. entstandene Schäden.</p>
              <p>•Die auf den Notebooks vorgenommene Softwareinstallation darf von den Ausleihenden nicht verändert werden.</p>
              <p>•Eventuelle Sonderregelungen, die über die hier festgelegten Bedingungen hinausgehen, müssen auf dem Verleihschein schriftlich festgehalten werden.</p>
              <p>•Die Nutzung erfolge nur ins Verreins Raumlichkeieten.</p>
              <p>•Bei Abholung der Geräte werden diese Nutzungsbedingungen durch Unterschrift auf dem Verleihschein anerkannt.</p>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div className="text-[9px]"> {/* Reduced from 10px */}
          <div className="font-bold text-xs mb-2"> {/* Reduced from sm */}
            VKII Ruhrbezirk e.V.<br />
            Verein Kamerunischer Ingenieure und Informatiker, Ruhrbezirk
          </div>

          <div className="grid grid-cols-4 gap-3"> {/* Reduced gap */}
            <div>
              Postfach:720359<br />
              44383 Dortmund
            </div>
            <div>
              Tel.0231-99214990<br />
              <a href="mailto:info@vkii-ruhrbezirk.de" className="text-green-600 underline">
                info@vkii-ruhrbezirk.de
              </a><br />
              <a href="http://www.vkii-ruhrbezirk.de" className="text-green-600 underline">
                www.vkii-ruhrbezirk.de
              </a>
            </div>
            <div>
              Sparkasse Dortmund<br />
              IBAN:DE49440501990911013835<br />
              BIC:DORTDE33XXXX
            </div>
            <div>
              Vereinsregister-Nr.:6994<br />
              Amtsgericht Dortmund HRB<br />
              St.-Nr.:314/5704/6683
            </div>
          </div>
        </div>

        {/* Print/Back Buttons - Only visible on screen */}
        <div className="print:hidden mt-5 flex justify-center gap-3">
          <button
            onClick={() => window.print()}
            className="bg-blue-600 text-white px-5 py-2 rounded-lg hover:bg-blue-700 transition-colors text-sm"
          >
            Drucken
          </button>
          <button
            onClick={onBack}
            className="bg-gray-100 text-gray-700 px-5 py-2 rounded-lg hover:bg-gray-200 transition-colors text-sm"
          >
            Zurück zum Formular
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoanPrintView;