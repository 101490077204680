
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Back Button Component
const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => navigate('/')}
      className="fixed top-4 left-4 flex items-center gap-2 px-4 py-2 bg-white rounded-lg shadow-md hover:shadow-lg transition-all z-50"
    >
      <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
      </svg>
      Zurück
    </button>
  );
};

// Layout Component
const Layout = ({ children }) => {
  const location = useLocation();

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="print:hidden">
        {location.pathname !== '/' && <BackButton />}
      </div>
      {children}
    </div>
  );
};

export default Layout;